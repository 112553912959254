// src/contexts/SocketContext.js
"use client"
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from "../reducers/AuthReducers";
import { selectRoomCode } from '@/reducers/MeetingReducer';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const roomId = useSelector(selectRoomCode);

  const currentUser = useSelector(selectCurrentUser);
  useEffect(() => {
    if (currentUser) {
      const socketInstance = io(process.env.NEXT_PUBLIC_BACKEND_API, {
        query: {
          userId: currentUser.id,
          roomId,
          userName: `${currentUser.firstName} ${currentUser.lastName}`,
        },
      });

      socketInstance.on('connect', () => {
        socketInstance.emit('join', currentUser.id);
        if (roomId) {
          socketInstance.emit("join_meeting", { roomId, userId: currentUser.id });
        }
      });

      socketInstance.on('userStatusUpdate', (data) => {
        const { userId, lastSeen } = data;
        console.log('User Status Update:', lastSeen);
      });

      socketInstance.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });

      socketInstance.on('disconnect', (reason) => {
        console.log('WebSocket connection closed:', reason);
      });

      setSocket(socketInstance);

      return () => {
        socketInstance.disconnect();
      };
    }
  }, [currentUser]);


  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
