import { createSlice } from '@reduxjs/toolkit';

const meetingSlice = createSlice({
  name: 'meeting',
  initialState: {
    roomCode: '',
    currentParticipant: {},
    participants: [],
    userSettings: {
      micEnabled: true,
      videoEnabled: true,
    },
    loading: false,
  },
  reducers: {
    setRoomCode: (state, action) => {
      state.roomCode = action.payload;
    },
    setCurrentParticipant: (state, action) => {
      state.currentParticipant = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    addParticipant: (state, action) => {
      const participantExists = state.participants.some(
        (p) => p.userId === action.payload.userId
      );
      if (!participantExists) {
        state.participants.push(action.payload);
      }
    },

    removeParticipant: (state, action) => {
      state.participants = state.participants.filter(
        (participant) => participant.userId !== action.payload
      );
    },
    setUserSettings: (state, action) => {
      state.userSettings = { ...state.userSettings, ...action.payload };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleMic: (state, action) => {
      const participant = state.participants.find(
        (p) => p.userId === action.payload
      );
      if (participant) {
        participant.isMuted = !participant.isMuted;
      }
    },
    toggleVideo: (state, action) => {
      const participant = state.participants.find(
        (p) => p.userId === action.payload
      );
      if (participant) {
        participant.isVideoOn = !participant.isVideoOn;
      }
    },
    toggleHand: (state, action) => {
      const participant = state.participants.find(
        (p) => p.userId === action.payload
      );
      if (participant) {
        participant.isVideoOn = !participant.isVideoOn;
      }
    },
    toggleLeave: (state, action) => {
      const participant = state.participants.find(
        (p) => p.userId === action.payload
      );
      if (participant) {
        participant.isVideoOn = !participant.isVideoOn;
      }
    },
  },
});

export const {
  setRoomCode,
  addParticipant,
  removeParticipant,
  setUserSettings,
  setLoading,
  setParticipants,
  toggleMic,
  toggleVideo,
  setCurrentParticipant,
} = meetingSlice.actions;

export default meetingSlice.reducer;

export const selectRoomCode = (state) => state.meeting.roomCode;
export const selectCurrentParticipant = (state) => state.meeting.currentParticipant;
export const selectParticipants = (state) => state.meeting.participants;
export const selectUserSettings = (state) => state.meeting.userSettings;
export const selectLoading = (state) => state.meeting.loading;
